<template>
  <div id="blocks">
    <AdminTitle title="Panels">
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <v-btn
            @click="refresh()"
            :loading="status.gettingAll"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-btn
            @click="showDialog()"
            color="primary"
            small
          >
            Import New Panel
          </v-btn>

          <v-btn
            @click="showAddCategoryDialog()"
            color="primary"
            outlined
            small
          >
            Add Category
          </v-btn>
        </div>
      </template>
    </AdminTitle>

    <div>
      <preloader v-if="status.gettingAll || status.gettingCategories" />

      <v-alert v-if="!status.gettingCategories && !categories.length" type="info" text>
        No category found
      </v-alert>

      <v-expansion-panels
        v-if="!status.gettingAll && !status.gettingCategories && categories.length"
        focusable
        outlined
        hover
      >
        <draggable v-model="compCategories" tag="div" style="width: 100%">
          <v-expansion-panel class="box-shadow" v-for="category in compCategories" :key="category.id">
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <div>
                <v-icon v-if="category.header" color="primary" small left>mdi-page-layout-header</v-icon>
                <v-icon v-if="category.footer" color="primary" small left>mdi-page-layout-footer</v-icon>
                <span>{{ category.name }}</span> <v-chip color="grey lighten-1" class="ml-2" dark x-small>{{ blocksByType(category.id).length }}</v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5">
              <div class="d-flex align-center gap-10 mb-5">
                <v-combobox
                  v-model="category.tags"
                  @blur="updateCategoryTags(category)"
                  :loading="status.updatingTags"
                  :disabled="status.updatingTags"
                  deletable-chips
                  hide-details
                  label="Tags"
                  small-chips
                  outlined
                  multiple
                  chips
                ></v-combobox>

                <v-menu bottom left offset-y transition="slide-x-transition" nudge-bottom="12">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      class="subtle"
                      v-on="on"
                      icon
                      small
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-list min-width="120">
                    <v-list-item @click="showDialog(category.id)" dense class="subtle">
                      <v-list-item-title class="primary--text"><v-icon small left>mdi-plus-box-outline</v-icon> Import New Panel</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="!category.header && !category.footer"
                      @click="setHeader(category)"
                      class="subtle"
                      dense
                    >
                      <v-list-item-title class="primary--text"><v-icon small left>mdi-page-layout-header</v-icon> Set As Header</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="!category.header && !category.footer"
                      @click="setFooter(category)"
                      class="subtle"
                      dense
                    >
                      <v-list-item-title class="primary--text"><v-icon small left>mdi-page-layout-footer</v-icon> Set As Footer</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateCategory(category)" dense class="subtle">
                      <v-list-item-title class="primary--text"><v-icon small left>mdi-form-textbox</v-icon> Rename</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="showDeleteCategoryDialog(category)" class="subtle" dense>
                      <v-list-item-title><v-icon small left class="red--text">mdi-delete-outline</v-icon> <span class="red--text">Delete</span> </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <!-- DESCRIPTION -->

              <v-textarea
                v-model="category.description"
                @blur="updateDescription(category)"
                :loading="status.updatingDescription"
                label="Description"
                outlined
                rows="2"
              ></v-textarea>

              <v-row v-if="blocksByType(category.id).length">
                <v-col cols="3" v-for="block in blocksByType(category.id)" :key="block.id">
                  <v-card hover outlined>
                    <v-card-text>
                      <BlockImgAdvance :block="block" />

                      <div class="mt-4">
                        <h5 class="text-center body-2 font-weight-bold">{{ block.name }}</h5>
                        <p class="caption grey--text text-center mb-0">{{ block.createdAt | formatDate }}</p>
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-space-between">
                      <v-btn @click="editBlock(block)" text small color="primary" class="subtle">
                        <v-icon left>mdi-square-edit-outline</v-icon>
                        <span class="font-weight-regular text-none">Edit Panel</span>
                      </v-btn>

                      <div class="d-flex">
                        <v-divider vertical class="mx-2 mt-n2 mb-n2"></v-divider>

                        <v-menu bottom left offset-y transition="slide-x-transition" nudge-bottom="12">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="primary"
                              class="subtle"
                              icon
                              v-on="on"
                              small
                            >
                              <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item @click="showCodeDialog(block)" dense class="subtle">
                              <v-list-item-title class="primary--text"><v-icon small left>mdi-code-brackets</v-icon> View Code</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item @click="deleteBlock(block)" class="subtle" dense>
                              <v-list-item-title><v-icon small left class="red--text">mdi-delete-outline</v-icon> <span class="red--text">Delete Block</span> </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <v-alert type="info" border="left" class="mb-0" text>No block found.</v-alert>
                </v-col>
              </v-row>

              <div class="pt-5 d-flex justify-end">
                <v-menu bottom left offset-y transition="slide-x-transition" nudge-bottom="12">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      class="subtle"
                      v-on="on"
                      icon
                      small
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-list min-width="120">
                    <v-list-item @click="showDialog(category.id)" dense class="subtle">
                      <v-list-item-title class="primary--text"><v-icon small left>mdi-plus-box-outline</v-icon> Import New Panel</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="!category.header && !category.footer"
                      @click="setHeader(category)"
                      class="subtle"
                      dense
                    >
                      <v-list-item-title class="primary--text"><v-icon small left>mdi-page-layout-header</v-icon> Set As Header</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="!category.header && !category.footer"
                      @click="setFooter(category)"
                      class="subtle"
                      dense
                    >
                      <v-list-item-title class="primary--text"><v-icon small left>mdi-page-layout-footer</v-icon> Set As Footer</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateCategory(category)" dense class="subtle">
                      <v-list-item-title class="primary--text"><v-icon small left>mdi-form-textbox</v-icon> Rename</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="showDeleteCategoryDialog(category)" class="subtle" dense>
                      <v-list-item-title><v-icon small left class="red--text">mdi-delete-outline</v-icon> <span class="red--text">Delete</span> </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </draggable>
      </v-expansion-panels>

    </div>


    <!-- ADD BLOCK -->
    <AddBlock />

    <!-- CONFIRM DELETE -->
    <ConfirmDelete
      :show="showDeleteDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @confirmed="deleteConfirmed()"
      @cancel="showDeleteDialog = false"
    />

    <!-- CODE POPUP -->
    <code-popup
      :show="showCodePopup"
      :block="codeBlock"
      @close="hideCodeDialog()"
    />

    <!-- ADD CATEGORY DIALOG -->
    <add-category
      :show="showAddCategory"
      @close="closeAddCategoryDialog()"
    />

    <!-- DELETE CATEGORY -->
    <delete-category
      :show="showDeleteCategory"
      :category="toDeleteCategory"
      @confirmed="deleteCategoryConfirmed"
      @close="hideDeleteCategoryDialog()"
    />
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import AddBlock from '@/components/AddBlockDialog'
import AddCategory from '@/components/AddCategoryDialog'
import DeleteCategory from '@/components/DeleteBlockCategoryDialog'
import draggable from 'vuedraggable'

export default {
  name: 'Blocks',

  metaInfo: {
    title: 'Panels'
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    AddBlock,
    AddCategory,
    DeleteCategory,
    draggable,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      toDelete: null,
      deleteMessage: '',
      showDeleteDialog: false,
      showCodePopup: false,
      codeBlock: null,
      showAddCategory: false,
      showDeleteCategory: false,
      toDeleteCategory: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      blocks: state => state.blocks.blocks,
      status: state => state.blocks.status,
      panels: state => state.blocks.panels,
      categories: state => state.blocks.categories,
    }),

    ...mapGetters('blocks', [
      'blocksByType',
    ]),

    compCategories: {
      get() {
        return this.categories
      },

      set(newVal) {
        this.$store.commit('blocks/updateCategories', newVal)
        this.reorderCategories()
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('blocks', [
      'delete',
      'setHeader',
      'getBlocks',
      'setFooter',
      'getCategories',
      'deleteCategory',
      'reorderCategories',
      'updateCategoryTags',
      'updateDescription'
    ]),

    refresh() {
      this.getBlocks()
      this.getCategories()
    },

    showDialog(cat = null) {
      if (cat) this.$store.commit('blocks/setDefaultCategory', cat)
      this.$store.commit('blocks/setShowDialog', true)
    },

    deleteBlock(block) {
      this.showDeleteDialog = true
      this.deleteMessage = `Are you sure you want to delete "${block.name}" block?`
      this.toDelete = block
    },

    deleteConfirmed() {
      Promise.all([this.delete(this.toDelete)])
      .then(() => {
        this.showDeleteDialog = false
      })
    },

    editBlock(block) {
      this.$store.commit('blocks/setData', block)
      this.showDialog()
    },

    showCodeDialog(block) {
      this.showCodePopup = true
      this.codeBlock = block
    },

    hideCodeDialog() {
      this.showCodePopup = false
    },

    showAddCategoryDialog() {
      this.showAddCategory = true
    },

    closeAddCategoryDialog() {
      this.showAddCategory = false
    },

    updateCategory(category) {
      this.$store.commit('blocks/setCategoryData', category)
      this.showAddCategoryDialog()
    },

    showDeleteCategoryDialog(category) {
      this.toDeleteCategory = category

      if (this.blocksByType(category.id).length) {
        this.showDeleteCategory = true
      }
      else {
        this.deleteCategoryConfirmed()
      }
    },

    hideDeleteCategoryDialog() {
      this.showDeleteCategory = false
    },

    deleteCategoryConfirmed(newCategory = null) {
      Promise.all([this.deleteCategory({
        newCategory,
        category: this.toDeleteCategory
      })])
      .then(() => {
        this.hideDeleteCategoryDialog()
      })
    }
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    if (!this.status.firstLoad) this.getBlocks()
    if (!this.status.firstLoadCats) this.getCategories()
  }

}

</script>

<style>

#blocks .v-expansion-panel:before {
  box-shadow: 0 0 0 1px #e0e0e0 !important;
}

</style>
