<template>
  <v-dialog
    :value="status.showDialog"
    :max-width="$dialog.medium"
    no-click-animation
    persistent
    scrollable
  >
    <close-btn @click="closeDialog()" overflow />
    <v-card>
      <v-card-title>
          <h5 class="font-weight-bold text-h6 mr-5" v-html="data.id ? `Update Panel <span class='primary--text'>${data.name}</span>` : 'Import New Panel'"></h5>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-10">
          <div class="mb-5 body-1 grey--text">
            Upload your block as SVG and the layout options below.
          </div>

          <v-form ref="blockForm" @submit.prevent="validateForm()">
            <v-text-field
              :rules="[rules.required]"
              v-model="data.name"
              label="Panel Name"
              outlined
            ></v-text-field>

            <v-select
              :rules="[rules.required]"
              v-model="data.type"
              :items="categories"
              label="Panel Type"
              item-text="name"
              item-value="id"
              outlined
            ></v-select>

            <label class="font-weight-bold">Columns</label>

            <v-radio-group v-model="data.column" :rules="[rules.required]" row dense>
              <v-radio v-for="col in 8" :key="col" :label="String(col)" :value="col"></v-radio>
            </v-radio-group>

            <label class="font-weight-bold">Enable Panel</label>

            <v-switch v-model="data.enable" color="success"></v-switch>

            <v-divider class="mb-5"></v-divider>

            <div class="mb-1">
              <label class="font-weight-bold text-h6">
                Upload Panel Files
              </label>
            </div>

            <p class="grey--text caption">
              Upload dark/light/grey variations of the panel and its orientations in SVG.
            </p>

            <label class="font-weight-bold">Orientations</label>

            <div class="d-flex gap-20">
              <v-checkbox v-model="data.orientations" value="left" label="Left"></v-checkbox>
              <v-checkbox v-model="data.orientations" value="center" label="Center"></v-checkbox>
              <v-checkbox v-model="data.orientations" value="right" label="Right"></v-checkbox>
            </div>


            <v-tabs fixed-tabs>
              <v-tab class="font-weight-bold" v-if="data.orientations.includes('left')">Left-Aligned</v-tab>
              <v-tab class="font-weight-bold" v-if="data.orientations.includes('center')">Centered</v-tab>
              <v-tab class="font-weight-bold" v-if="data.orientations.includes('right')">Right-Aligned</v-tab>

              <!------------------------- LEFT ------------------------->
              
              <v-tab-item v-if="data.orientations.includes('left')" class="box-shadow">
                <v-card flat>
                  <v-card-text class="pa-7">

                    <!-- DARK SVG -->
                    <text-divider text="Dark" />

                    <template v-if="data.leftDarkImg && (typeof data.leftDarkImg !== 'object')">
                      <v-card class="mb-5" outlined tile>
                        <BlockImg :data="data" type="leftDarkImg" editable />
                      </v-card>
                    </template>

                    <v-file-input
                      v-if="!data.leftDarkImg || typeof data.leftDarkImg == 'object'"
                      prepend-inner-icon="mdi-image"
                      v-model="data.leftDarkImg"
                      accept="image/svg+xml"
                      prepend-icon=""
                      color="primary"
                      class="dashed"
                      label="Dark"
                      clearable
                      outlined
                    ></v-file-input>
                    
                    <v-file-input
                      v-if="!data.leftDarkImgJpeg || typeof data.leftDarkImgJpeg == 'object'"
                      v-model="data.leftDarkImgJpeg"
                      prepend-inner-icon="mdi-image"
                      accept="image/jpeg"
                      label="Dark JPEG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>

                    <template v-else>
                      <v-text-field
                        @click:append="deleteImage({
                          file: data.leftDarkImgJpeg,
                          type: 'leftDarkImgJpeg',
                          id: data.id,
                        })"
                        :value="data.leftDarkImgJpeg"
                        append-icon="mdi-close"
                        label="JPEG"
                        outlined
                        readonly
                        dense
                      ></v-text-field>

                      <jpeg-preview :file="data.leftDarkImgJpeg" />
                    </template>

                    <v-textarea
                      v-model="data.leftDarkShortcode"
                      label="Shortcode"
                      outlined
                    ></v-textarea>

                    <v-textarea
                      v-model="data.leftDarkCss"
                      label="CSS"
                      outlined
                    ></v-textarea>

                    <!-- GREY -->
                    <text-divider text="Grey" />

                    <template v-if="data.leftGreyImg && (typeof data.leftGreyImg !== 'object')">
                      <v-card class="mb-5" outlined flat>
                        <BlockImg :data="data" type="leftGreyImg" editable />
                      </v-card>
                    </template>

                    <v-file-input
                      v-if="!data.leftGreyImg || typeof data.leftGreyImg == 'object'"
                      prepend-inner-icon="mdi-image"
                      v-model="data.leftGreyImg"
                      accept="image/svg+xml"
                      prepend-icon=""
                      class="dashed"
                      label="Grey"
                      clearable
                      outlined
                    ></v-file-input>

                    <v-file-input
                      v-if="!data.leftGreyImgJpeg || typeof data.leftGreyImgJpeg == 'object'"
                      v-model="data.leftGreyImgJpeg"
                      prepend-inner-icon="mdi-image"
                      accept="image/jpeg"
                      label="Grey JPEG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>

                    <template v-else>
                      <v-text-field
                        @click:append="deleteImage({
                          file: data.leftGreyImgJpeg,
                          id: data.id,
                          type: 'leftGreyImgJpeg'
                        })"
                        :value="data.leftGreyImgJpeg"
                        append-icon="mdi-close"
                        label="JPEG"
                        outlined
                        readonly
                        dense
                      ></v-text-field>

                      <jpeg-preview :file="data.leftGreyImgJpeg" />
                    </template>

                    <v-textarea
                      v-model="data.leftGreyShortcode"
                      label="Shortcode"
                      outlined
                    ></v-textarea>

                    <v-textarea
                      v-model="data.leftGreyCss"
                      label="CSS"
                      outlined
                    ></v-textarea>

                    <!-- lIGHT SVG -->

                    <text-divider text="Light" />

                    <template v-if="data.leftLightImg && (typeof data.leftLightImg !== 'object')">
                      <v-card class="mb-5" outlined flat>
                        <BlockImg :data="data" type="leftLightImg" editable />
                      </v-card>
                    </template>

                    <v-file-input
                      v-if="!data.leftLightImg || typeof data.leftLightImg == 'object'"
                      prepend-inner-icon="mdi-image"
                      v-model="data.leftLightImg"
                      accept="image/svg+xml"
                      prepend-icon=""
                      class="dashed"
                      label="Light"
                      clearable
                      outlined
                    ></v-file-input>

                    <v-file-input
                      v-if="!data.leftLightImgJpeg || typeof data.leftLightImgJpeg == 'object'"
                      v-model="data.leftLightImgJpeg"
                      prepend-inner-icon="mdi-image"
                      accept="image/jpeg"
                      label="Light JPEG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>

                    <template v-else>
                      <v-text-field
                        @click:append="deleteImage({
                          file: data.leftLightImgJpeg,
                          id: data.id,
                          type: 'leftLightImgJpeg'
                        })"
                        :value="data.leftLightImgJpeg"
                        append-icon="mdi-close"
                        label="JPEG"
                        outlined
                        readonly
                        dense
                      ></v-text-field>

                      <jpeg-preview :file="data.leftLightImgJpeg" />
                    </template>

                    <v-textarea
                      v-model="data.leftLightShortcode"
                      label="Shortcode"
                      outlined
                    ></v-textarea>

                    <v-textarea
                      v-model="data.leftLightCss"
                      label="CSS"
                      outlined
                    ></v-textarea>

                  </v-card-text>
                </v-card>
              </v-tab-item>
              
              <!------------------------- CENTER ------------------------->
              
              <v-tab-item v-if="data.orientations.includes('center')" class="box-shadow">
                <v-card flat>
                  <v-card-text class="pa-7">

                    <text-divider text="Dark" />

                    <template v-if="data.centerDarkImg && (typeof data.centerDarkImg !== 'object')">
                      <v-card class="mb-5" outlined flat>
                        <BlockImg :data="data" type="centerDarkImg" editable />
                      </v-card>
                    </template>

                    <v-file-input
                      v-if="!data.centerDarkImg || typeof data.centerDarkImg == 'object'"
                      prepend-inner-icon="mdi-image"
                      v-model="data.centerDarkImg"
                      accept="image/svg+xml"
                      label="Dark SVG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>
                    
                    <v-file-input
                      v-if="!data.centerDarkImgJpeg || typeof data.centerDarkImgJpeg == 'object'"
                      v-model="data.centerDarkImgJpeg"
                      prepend-inner-icon="mdi-image"
                      accept="image/jpeg"
                      label="Dark JPEG"
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>

                    <template v-else>
                      <v-text-field
                        @click:append="deleteImage({
                          file: data.centerDarkImgJpeg,
                          id: data.id,
                          type: 'centerDarkImgJpeg'
                        })"
                        :value="data.centerDarkImgJpeg"
                        append-icon="mdi-close"
                        label="JPEG"
                        outlined
                        readonly
                        dense
                      ></v-text-field>

                     <jpeg-preview :file="data.centerDarkImgJpeg" />
                    </template>

                    <v-textarea
                      v-model="data.centerDarkShortcode"
                      label="Shortcode"
                      outlined
                    ></v-textarea>

                    <v-textarea
                      v-model="data.centerDarkCss"
                      label="CSS"
                      outlined
                    ></v-textarea>

                    <!-- GREY -->

                    <text-divider text="Grey" />

                    <template v-if="data.centerGreyImg && (typeof data.centerGreyImg !== 'object')">
                      <v-card class="mb-5" outlined flat>
                        <BlockImg :data="data" type="centerGreyImg" editable />
                      </v-card>
                    </template>

                    <v-file-input
                      v-if="!data.centerGreyImg || typeof data.centerGreyImg == 'object'"
                      prepend-inner-icon="mdi-image"
                      v-model="data.centerGreyImg"
                      accept="image/svg+xml"
                      label="Grey SVG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>
                    
                    <v-file-input
                      v-if="!data.centerGreyImgJpeg || typeof data.centerGreyImgJpeg == 'object'"
                      v-model="data.centerGreyImgJpeg"
                      prepend-inner-icon="mdi-image"
                      accept="image/jpeg"
                      label="Grey JPEG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>


                    <template v-else>
                      <v-text-field
                        @click:append="deleteImage({
                          file: data.centerGreyImgJpeg,
                          id: data.id,
                          type: 'centerGreyImgJpeg'
                        })"
                        :value="data.centerGreyImgJpeg"
                        append-icon="mdi-close"
                        label="JPEG"
                        outlined
                        readonly
                        dense
                      ></v-text-field>

                      <jpeg-preview :file="data.centerGreyImgJpeg" />
                    </template>

                    <v-textarea
                      v-model="data.centerGreyShortcode"
                      label="Shortcode"
                      outlined
                    ></v-textarea>

                    <v-textarea
                      v-model="data.centerGreyCss"
                      label="CSS"
                      outlined
                    ></v-textarea>

                    <!-- LIGHT -->

                    <text-divider text="Light" />

                    <template v-if="data.centerLightImg && (typeof data.centerLightImg !== 'object')">
                      <v-card class="mb-5" outlined flat>
                        <BlockImg :data="data" type="centerLightImg" editable />
                      </v-card>
                    </template>

                    <v-file-input
                      v-if="!data.centerLightImg || typeof data.centerLightImg == 'object'"
                      prepend-inner-icon="mdi-image"
                      v-model="data.centerLightImg"
                      accept="image/svg+xml"
                      label="Light SVG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>
                    
                    <v-file-input
                      v-if="!data.centerLightImgJpeg || typeof data.centerLightImgJpeg == 'object'"
                      v-model="data.centerLightImgJpeg"
                      prepend-inner-icon="mdi-image"
                      accept="image/jpeg"
                      label="Light JPEG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>

                    <template v-else>
                      <v-text-field
                        @click:append="deleteImage({
                          file: data.centerLightImgJpeg,
                          id: data.id,
                          type: 'centerLightImgJpeg'
                        })"
                        :value="data.centerLightImgJpeg"
                        append-icon="mdi-close"
                        label="JPEG"
                        outlined
                        readonly
                        dense
                      ></v-text-field>
                      
                      <jpeg-preview :file="data.centerLightImgJpeg" />
                    </template>
                    

                    <v-textarea
                      v-model="data.centerLightShortcode"
                      label="Shortcode"
                      outlined
                    ></v-textarea>

                    <v-textarea
                      v-model="data.centerLightCss"
                      label="CSS"
                      outlined
                    ></v-textarea>

                  </v-card-text>
                </v-card>
              </v-tab-item>
              
              <!------------------------- RIGHT ------------------------->
              
              <v-tab-item v-if="data.orientations.includes('right')" class="box-shadow">
                <v-card flat>
                  <v-card-text class="pa-7">

                    <text-divider text="Dark" />

                    <template v-if="data.rightDarkImg && (typeof data.rightDarkImg !== 'object')">
                      <v-card class="mb-5" outlined flat>
                        <BlockImg :data="data" type="rightDarkImg" editable />
                      </v-card>
                    </template>

                    <v-file-input
                      v-if="!data.rightDarkImg || typeof data.rightDarkImg == 'object'"
                      prepend-inner-icon="mdi-image"
                      v-model="data.rightDarkImg"
                      accept="image/svg+xml"
                      label="Dark SVG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>

                    <v-file-input
                      v-if="!data.rightDarkImgJpeg || typeof data.rightDarkImgJpeg == 'object'"
                      v-model="data.rightDarkImgJpeg"
                      prepend-inner-icon="mdi-image"
                      accept="image/jpeg"
                      label="Dark JPEG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>

                    <template v-else>
                      <v-text-field
                        @click:append="deleteImage({
                          file: data.rightDarkImgJpeg,
                          id: data.id,
                          type: 'rightDarkImgJpeg'
                        })"
                        :value="data.rightDarkImgJpeg"
                        append-icon="mdi-close"
                        label="JPEG"
                        outlined
                        readonly
                        dense
                      ></v-text-field>

                      <jpeg-preview :file="data.rightDarkImgJpeg" />
                    </template>

                    <v-textarea
                      v-model="data.rightDarkShortcode"
                      label="Shortcode"
                      outlined
                    ></v-textarea>

                    <v-textarea
                      v-model="data.rightDarkCss"
                      label="CSS"
                      outlined
                    ></v-textarea>

                    <!-- GREY -->

                    <text-divider text="Grey" />

                    <template v-if="data.rightGreyImg && (typeof data.rightGreyImg !== 'object')">
                      <v-card class="mb-5" outlined flat>
                        <BlockImg :data="data" type="rightGreyImg" editable />
                      </v-card>
                    </template>

                    <v-file-input
                      v-if="!data.rightGreyImg || typeof data.rightGreyImg == 'object'"
                      prepend-inner-icon="mdi-image"
                      v-model="data.rightGreyImg"
                      accept="image/svg+xml"
                      prepend-icon=""
                      class="dashed"
                      label="Grey"
                      clearable
                      outlined
                    ></v-file-input>

                    <v-file-input
                      v-if="!data.rightGreyImgJpeg || typeof data.rightGreyImgJpeg == 'object'"
                      v-model="data.rightGreyImgJpeg"
                      prepend-inner-icon="mdi-image"
                      accept="image/jpeg"
                      label="Grey JPEG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>

                    <template v-else>
                      <v-text-field
                        @click:append="deleteImage({
                          file: data.rightGreyImgJpeg,
                          id: data.id,
                          type: 'rightGreyImgJpeg'
                        })"
                        :value="data.rightGreyImgJpeg"
                        append-icon="mdi-close"
                        label="JPEG"
                        outlined
                        readonly
                        dense
                      ></v-text-field>
                      
                      <jpeg-preview :file="data.rightGreyImgJpeg" />
                    </template>
                    

                    <v-textarea
                      v-model="data.rightGreyShortcode"
                      label="Shortcode"
                      outlined
                    ></v-textarea>

                    <v-textarea
                      v-model="data.rightGreyCss"
                      label="CSS"
                      outlined
                    ></v-textarea>

                    <!-- LIGHT -->

                    <text-divider text="Light" />

                    <template v-if="data.rightLightImg && (typeof data.rightLightImg !== 'object')">
                      <v-card class="mb-5" outlined flat>
                        <BlockImg :data="data" type="rightLightImg" editable />
                      </v-card>
                    </template>

                    <v-file-input
                      v-if="!data.rightLightImg || typeof data.rightLightImg == 'object'"
                      prepend-inner-icon="mdi-image"
                      v-model="data.rightLightImg"
                      accept="image/svg+xml"
                      prepend-icon=""
                      class="dashed"
                      label="Light"
                      clearable
                      outlined
                    ></v-file-input>

                    <v-file-input
                      v-if="!data.rightLightImgJpeg || typeof data.rightLightImgJpeg == 'object'"
                      v-model="data.rightLightImgJpeg"
                      prepend-inner-icon="mdi-image"
                      accept="image/jpeg"
                      label="Light JPEG"
                      prepend-icon=""
                      class="dashed"
                      clearable
                      outlined
                    ></v-file-input>

                    <template v-else>
                      <v-text-field
                        @click:append="deleteImage({
                          file: data.rightLightImgJpeg,
                          id: data.id,
                          type: 'rightLightImgJpeg'
                        })"
                        :value="data.rightLightImgJpeg"
                        append-icon="mdi-close"
                        label="JPEG"
                        outlined
                        readonly
                        dense
                      ></v-text-field>

                      <jpeg-preview :file="data.rightLightImgJpeg" />
                    </template>

                    <v-textarea
                      v-model="data.rightLightShortcode"
                      label="Shortcode"
                      outlined
                    ></v-textarea>

                    <v-textarea
                      v-model="data.rightLightCss"
                      label="CSS"
                      outlined
                    ></v-textarea>

                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <div v-if="hasError" class="mt-3 caption red--text text-center">
            {{ hasError }}
          </div>

          <v-btn
            :loading="status.creating"
            @click="validateForm()"
            color="primary"
            small
          >Save Panel</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from '@/rules'
import JpegPreview from '@/views/panels/JpegPreview'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  data() {
    return {
      rules,
      hasError: null,
    }
  },

  components: {
    JpegPreview
  },

  computed: {
    ...mapState({
      data: state => state.blocks.data,
      status: state => state.blocks.status,
      uploads: state => state.blocks.uploads,
      uploading: state => state.blocks.uploading,
      categories: state => state.blocks.categories,
    }),
  },

  watch: {
    'uploads': function (value) {
      if (!value.length && this.status.uploading) {
        this.closeDialog()

        if (this.$store.state.blocks.blocks.find(block => block.id == this.status.new)) {
          this.$store.dispatch('showSuccess', 'Panel successfully updated.')
        }
        else {
          this.$store.dispatch('showSuccess', 'Panel successfully added.')
        }

        this.getBlock(this.status.new)
        this.resetUpload()
        this.$refs.blockForm.resetValidation()
      }
    }
  },

  methods: {
    ...mapActions('blocks', [
      'create',
      'update',
      'getBlock',
      'deleteImage'
    ]),

    ...mapMutations('blocks', [
      'setData',
      'resetUpload',
      'setShowDialog',
    ]),

    closeDialog() {
      this.setShowDialog(false)
      this.$store.commit('blocks/resetData')
      this.$refs.blockForm.resetValidation()
    },

    validateForm() {
      this.hasError = null

      if (this.$refs.blockForm.validate()) {
        if (!this.data.id) {
          this.create()
        }
        else {
          this.update()
        }
      }
      else {
        this.hasError = 'Check for any error.'
      }
    },
  }
}

</script>
