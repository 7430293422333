<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.content"
    no-click-animation
    persistent
  >
    <v-card>
      <v-card-title>
        <h5 class="font-weight-bold text-h5">{{ data.id ? 'Update Category' : 'Add New Category' }}</h5>
        
        <close-btn @click="close()" depressed />
      </v-card-title>
      <v-card-text class="pt-3">
        <v-alert v-if="status.categoryError" type="error" border="left" class="mb-7">{{ status.categoryError }}</v-alert>

        <v-form ref="categoryForm" @submit.prevent="validateForm()">
          <v-text-field
            v-model="data.name"
            :rules="[rules.required]"
            label="Category Name"
            outlined
          ></v-text-field>

          <div class="d-flex gap-10">
            <v-btn
              @click="validateForm()"
              :loading="status.creatingCategory"
              color="accent"
              class="gradient"
              block
            >Submit</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import rules from '@/rules'

export default {
  props: {
    show: Boolean
  },

  data() {
    return {
      rules,
    }
  },

  computed: {
    ...mapState({
      data: state => state.blocks.catData,
      status: state => state.blocks.status,
    })
  },

  methods: {
    ...mapActions('blocks', [
      'createCategory',
      'updateCategory'
    ]),

    close() {
      this.$emit('close')
      this.$store.commit('blocks/setCategoryData', {})
      this.$refs.categoryForm.resetValidation()
    },

    validateForm() {
      if (this.$refs.categoryForm.validate()) {
        let task

        if (this.data.id) task = Promise.all([this.updateCategory()])
        else task = Promise.all([this.createCategory()])

        task.then(response => {
          if (!response[0]) {
            this.close()
          }
        })
      }
    },
  }
}

</script>
