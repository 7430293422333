<template>
  <v-card class="mb-5" outlined tile>
    <v-img
      v-if="url"
      :src="url"
    ></v-img>
  </v-card>
</template>

<script>
import firebase from 'firebase'

export default {
  props: ['file'],

  data() {
    return {
      url: null,
    }
  },

  methods: {
    getUrl() {
      var storage  = firebase.storage()

      if (this.file) {
        storage.ref(`blocks/${this.file}`)
        .getDownloadURL()
        .then(async (url) => {
          this.url = url
        })
        .catch(error => {
          console.log(error.message)
        })
      }
    }
  },

  mounted() {
    this.getUrl()
  }
}
</script>