<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.medium"
    persistent
    no-click-animation
  >
    <v-card class="py-5" v-if="category">
      <v-sheet :max-width="$dialog.content" class="mx-auto">
        <v-card-title class="justify-center">
          <h5 class="font-weight-bold text-h5 text-center">Delete "{{ category.name }}" folder?</h5>

          <v-btn
            @click="closeDialog()"
            icon
            absolute
            right
            top
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="text-center grey--text body-1">Please select a category where to transfer the <br> blocks inside this category.</p>

          <v-alert type="error" border="left" v-if="status.error">{{ status.error }}</v-alert>

          <v-form ref="deleteCategoryForm" @submit.prevent="validateForm()">
            <v-select
              v-model="newCategory"
              label="Select a category"
              :rules="[rules.required]"
              :items="filtered"
              item-text="name"
              item-value="id"
              prepend-inner-icon="mdi-folder-outline"
              outlined
            ></v-select>

            <v-btn
              @click="validateForm()"
              :loading="status.deletingCategory"
              color="accent"
              class="gradient"
              block
            >
              Delete
            </v-btn>
          </v-form>
        </v-card-text>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from '@/rules'
import { mapState } from 'vuex'

export default {
  props: {
    show: Boolean,
    category: Object
  },

  data() {
    return {
      rules,
      newCategory: null,
    }
  },

  computed: {
    ...mapState({
      status: state => state.blocks.status,
    }),

    filtered: function () {
      return this.$store.getters['blocks/orderedCategories'].filter(cat => {
        return cat.id !== this.category.id
      })
    }
  },

  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.deleteCategoryForm.resetValidation()
    },

    validateForm() {
      if (this.$refs.deleteCategoryForm.validate()) {
        this.$emit('confirmed', this.newCategory)
      }
    }
  }
}

</script>
